import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { questionIcon } from "assets/icon/Icon_modal";
import { confirmModalConfig } from "components/templates/modalConfig";
import { MESSAGE_DOWNLOAD_TESTER_STATUS_LIST } from "enums/modalMessage";
import useMutationHook from "hooks/useMutationHook";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";

const TesterStatusListDownloadModal = ({ groupIdx, onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      eventReason: "",
    },
  });

  //* 응시자 현황 리스트 다운로드
  const { mutate: downloadTesterList, isLoading, isSuccess } = useMutationHook("testerStatusList", "HR");

  const handleDownload = (data) => {
    const { password, eventReason } = data;
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DOWNLOAD_TESTER_STATUS_LIST,
        title: "응시자 현황 리스트 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        downloadTesterList(
          {
            password: password,
            eventReason: eventReason,
            groupIdx: groupIdx,
          },
          {
            onSuccess: (res) => {
              window.open(`https://${res.data.data}`);
              onClose();
            },
          }
        );
      }
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(handleDownload)();
    }
  };
  return (
    <>
      <DialogTitle variant="h5">응시자 현황 다운로드</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(handleDownload)} onKeyDown={handleKeyPress}>
          <Stack mt={2} gap={0.5}>
            <Typography fontWeight={700}>다운로드 사유</Typography>
            <Controller
              name="eventReason"
              control={control}
              render={({ field }) => <TextField {...field} placeholder="해당 파일 다운로드 사유를 입력해 주세요." fullWidth size="small" />}
            />
          </Stack>
          <Stack mt={2} gap={0.5}>
            <Typography fontWeight={700}>파일 비밀번호 설정</Typography>
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "파일 비밀번호를 입력해 주세요.",
                },
                minLength: {
                  value: 10,
                  message: "비밀번호는 10자 이상 입력해 주세요.",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  placeholder="파일 비밀번호를 입력해 주세요."
                  error={errors.password && true}
                  helperText={errors && errors.password?.message}
                  FormHelperTextProps={{
                    sx: {
                      margin: 0,
                    },
                  }}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
        <Button type="submit" variant={"contained"} onClick={handleSubmit(handleDownload)}>
          확인
        </Button>
      </DialogActions>
    </>
  );
};

export default TesterStatusListDownloadModal;
